<template>
  <div>
    <form-summary
      v-if="!isValid"
      class="form-errors alert alert-danger"
      :validator="$v.form"
      :attributes="attributes"
    />

    <CForm>
      <div class="d-flex flex-column">
        <!-- country, lang -->
        <div class="mb-3 d-flex flex-column flex-md-row">
          <CSelect
            class="w-100 mr-2"
            label="Country:"
            placeholder="Please select.."
            :lazy="false"
            :options="authCountries"
            :value.sync="$v.form.countryId.$model"
          />

          <CSelect
            class="w-100"
            label="Language:"
            placeholder="Please select.."
            :lazy="false"
            :value.sync="$v.form.lang.$model"
            :options="allLanguages"
            :isValid="checkIfValid('lang')"
          />
        </div>

        <!-- restaurant_name, email -->
        <div class="mb-3 d-flex flex-column flex-md-row">
          <CInput
            class="w-100 mr-2"
            label="Name of Restaurant:"
            placeholder="Name of Restaurant"
            :lazy="false"
            :value.sync="$v.form.restaurant_name.$model"
            :isValid="checkIfValid('restaurant_name')"
          />

          <CInput
            class="w-100 mr-2 email"
            label="Email:"
            placeholder="Email"
            :lazy="false"
            :value.sync="$v.form.email.$model"
            :isValid="checkIfValid('email')"
          />
        </div>

        <!-- restaurant_tel, restaurant_gsm -->
        <div class="mb-3 d-flex flex-column flex-md-row">
          <CInput
            class="w-100 mr-2"
            label="Phone Number:"
            placeholder="Restaurant phone number"
            :lazy="false"
            :value.sync="$v.form.restaurant_tel.$model"
          />
          <CInput
            class="w-100"
            label="Mobile Number:"
            placeholder="Restaurant mobile number"
            :lazy="false"
            :value.sync="$v.form.restaurant_gsm.$model"
          />
        </div>

        <!-- website, facebookpage -->
        <div class="mb-3 d-flex flex-column flex-md-row">
          <CInput
            class="w-100 mr-2"
            label="Website:"
            placeholder="Website"
            :lazy="false"
            :value.sync="$v.form.website.$model"
          >
            <template #append>
              <CLink
                class="btn btn-info"
                role="button"
                target="_blank"
                :href="$v.form.website.$model | addUriScheme"
              >
                <CIcon name="cil-external-link"/>
              </CLink>
            </template>
          </CInput>

          <CInput
            class="w-100"
            label="Facebook Page:"
            placeholder="Facebook page"
            :lazy="false"
            :value.sync="$v.form.facebookpage.$model"
          >
            <template #append>
              <CLink
                class="btn btn-info"
                role="button"
                target="_blank"
                :href="$v.form.facebookpage.$model ? 'https://www.facebook.com/' + $v.form.facebookpage.$model : null"
              >
                <CIcon name="cil-external-link"/>
              </CLink>
            </template>
          </CInput>
        </div>

        <!-- slogan, note_public -->
        <div class="mb-3 d-flex flex-column flex-md-row">
          <CInput
            class="w-100 mr-2"
            label="Motto:"
            placeholder="Motto"
            :lazy="false"
            :value.sync="$v.form.slogan.$model"
          />

          <CInput
            class="w-100"
            label="Public note above menu:"
            placeholder="Public note above menu"
            :lazy="false"
            :value.sync="$v.form.note_public.$model"
          />
        </div>

        <!-- exclusive, onlyonlinepay -->
        <div class="mb-3 d-flex flex-column flex-md-row">
          <div class="form-group w-100">
            <label class="d-block" style="font-weight: 500">Exclusive:</label>
            <CSwitch
              :checked.sync="$v.form.exclusive.$model"
              labelOn="YES"
              labelOff="NO"
              color="success"
              shape="pill"
              class="mt-020"
              size="lg"
              :lazy="false" />
          </div>

          <div class="form-group w-100">
            <label class="d-block" style="font-weight: 500">Online Payment Only:</label>
            <CSwitch
              :checked.sync="$v.form.onlyonlinepay.$model"
              labelOn="YES"
              labelOff="NO"
              color="success"
              shape="pill"
              class="mt-020"
              size="lg"
              :lazy="false"
            />
          </div>
        </div>

        <!-- address section -->
        <section class="address mb-3">
          <!-- Verify Address From Google -->
          <CInput
            id="verify_address"
            :value.sync="verified_address"
            class="w-100"
            label="Verify Address From Google:"
            placeholder="Enter a location"
            :lazy="false"
          >
            <template #prepend>
              <CButton color="info"><CIcon name="cil-location-pin"/></CButton>
            </template>
          </CInput>

          <!-- restaurant_address -->
          <div class="d-flex flex-column flex-md-row">
            <CTextarea
              class="w-100"
              rows="2"
              label="Address:"
              placeholder="Street name and house number"
              :lazy="false"
              :value.sync="$v.form.restaurant_address.$model"
              :isValid="checkIfValid('restaurant_address')"
            />
          </div>

          <!-- restaurant_postcode, restaurant_city -->
          <div class="d-flex flex-column flex-md-row">
            <CInput
              id="PostalCodeNum"
              type="number"
              class="w-100 mr-2"
              label="Postal Code:"
              placeholder="Zip or Postal Code"
              :lazy="false"
              :value.sync="$v.form.restaurant_postcode.$model"
              :isValid="checkIfValid('restaurant_postcode')"
            />
            <CInput
              class="w-100 mr-2"
              label="City:"
              placeholder="City"
              :lazy="false"
              :value.sync="$v.form.restaurant_city.$model"
              :isValid="checkIfValid('restaurant_city')"
            />

            <CInput
              readonly
              class="w-100 mr-2"
              label="Latitude:"
              placeholder="Latitude"
              :lazy="false"
              :value.sync="$v.form.resto_lat.$model"
            >
              <template #prepend>
                <CLoadingButton
                  :loading="loading"
                  :color="(isCoordinateUptodate && !!form.resto_lat && !!form.resto_lat) ? 'dark' : 'warning'"
                  :disabled="isCoordinateUptodate && !!form.resto_lat && !!form.resto_lat"
                  :onChange="getCoordinateFromAddress"
                >
                  <CIcon name="cil-sync"/>
                </CLoadingButton>
              </template>
            </CInput>

            <CInput
              readonly
              class="w-100"
              label="Longitude:"
              placeholder="Longitude"
              :lazy="false"
              :value.sync="$v.form.resto_lng.$model"
            />
          </div>
        </section>
      </div>
    </CForm>
  </div>

</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email } from "vuelidate/lib/validators"

const hasHouseNumber = (restaurant_address) => {
  var regex = /\d+/g;
  return regex.test(restaurant_address);
}

export default {
  name: 'RestaurantWizardStep1',
  components: {
  },
  data() {
    return {
      loading: false,
      form: {},
      allLanguages: [],

      // Google Maps API Autocomplete
      verified_address: '',
      autocomplete_address: null,
      isCoordinateUptodate: false,

      // Vuelidate-error-extractor
      attributes: {
        countryId: 'Country',
        lang: 'Language of the restaurant',
        restaurant_name: 'Restaurant name',
        email: 'Restaurant email',
        restaurant_address: 'Restaurant address',
        restaurant_postcode: 'Restaurant postal code',
        restaurant_city: 'Restaurant city',
      },
    }
  },

  mixins: [validationMixin],
  validations: {
    form: {
      // General Information
      countryId: { required },
      lang: { required },
      exclusive: {},
      restaurant_name: { required, minLength: minLength(3) },
      restaurant_address: { required, hasHouseNumber },
      restaurant_postcode: { required },
      restaurant_city: { required },
      resto_lat: {},
      resto_lng: {},
      restaurant_tel: {},
      restaurant_gsm: {},
      website: {},
      email: {
        required,
        email: function () {
          if (!this.form.email) {
            return false;
          }
          const emails = this.form.email.split(";");
          return emails.map(email).reduce((res, val) => res && val, true);
        },
      },
      facebookpage: {},
      slogan: {},
      note_public: {},

      onlyonlinepay: {},
    }
  },

  watch: {
    addressFields(newVal, oldVal) { // Watching address, postcode, city changes
      this.isCoordinateUptodate = false;
    },
    'form.email': function(newVal, oldVal) { this.form.email = newVal ? newVal.toLowerCase() : newVal },
    'form.countryId': function(newVal, oldVal) {
      this.autocomplete_address.setComponentRestrictions({ country: [this.country?.iso2] });
    },
  },

  async mounted() {
    this.getAllLanguages();

    this.form = this.getForm();

    // Google Maps API Autocomplete: Place Autocomplete Address Form
    this.autocomplete_address = new google.maps.places.Autocomplete(
      document.getElementById("verify_address"),
      {
        componentRestrictions: { country: [this.country?.iso2] },
        fields: ["address_components", "geometry", "formatted_address"],
        types: ["address"],
      }
    );
    this.autocomplete_address.addListener("place_changed", this.setPlace);
    //(end) -----


    this.$nextTick(() => {
      // DOM updated
      setTimeout(() => {
        this.isCoordinateUptodate = true;
      }, 2000);

      this.$emit("loaded");
    });
  },

  computed: {
    isValid() { return !this.$v.form.$invalid },
    isDirty() { return this.$v.form.$anyDirty },
    itemId() { return this.$route.params.id },
    addressFields() {
      return `${this.form.restaurant_address}|${this.form.restaurant_postcode}|${this.form.restaurant_city}`;
    },
    country() {
      return this.authCountries.find((item) => item.value == (this.form.countryId || this.$store.state.countryId));
    },
  },

  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid);
      // return !(field.$invalid || field.$model === "");
    },
    validate() {
      this.$v.$touch()
    },
    resetForm() {
      this.form = this.getForm();
      this.$v.$reset()
    },

    getForm() {
      var form = {
        countryId: this.$store.state.countryId,
        lang: null,
        exclusive: false,
        restaurant_name: '',
        restaurant_address: null,
        restaurant_postcode: null,
        restaurant_city: null,
        resto_lat: null,
        resto_lng: null,
        restaurant_tel: null,
        restaurant_gsm: null,
        website: null,
        email: null,
        facebookpage: null,
        slogan: null,
        note_public: null,
        onlyonlinepay: false,
      };

      return JSON.parse(localStorage.getItem('pending-restaurant')) || form;
    },

    async next() {
      if (!this.isValid) {
        window.scroll({top: 180, behavior: 'smooth'});
        this.validate();
        return;
      }

      localStorage.setItem('pending-restaurant', JSON.stringify(this.form));
      this.$emit("next", true);
    },

    async previous() {
      this.$emit("previous", false);
    },

    async getAllLanguages() {
      this.allLanguages = JSON.parse(sessionStorage.getItem(`all-languages`)) || [];
      if (this.allLanguages.length) {
        return;
      }

      await this.$axios.get(this.$backend.SETTINGS.GET_LANGUAGES)
        .then((response) => {
          this.allLanguages = response.data;
          sessionStorage.setItem(`all-languages`, JSON.stringify(this.allLanguages));
        });
    },

    async getCoordinateFromAddress() {
      let geocoder = new google.maps.Geocoder();
      let address = `${this.form.restaurant_address} ${this.form.restaurant_postcode} ${this.form.restaurant_city} BE`;
      var vm = this;

      return new Promise(function (resolve, reject) {
        geocoder.geocode({ address: address }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            vm.verified_address = results[0].formatted_address;
            vm.form.resto_lat = results[0].geometry.location.lat();
            vm.form.resto_lng = results[0].geometry.location.lng();
            vm.isCoordinateUptodate = true;
            resolve('Coordinate received');
          } else {
            reject(status);
          }
        });
      });
    },

    setPlace() {
      // Get the place details from the autocomplete object.
      const place = this.autocomplete_address.getPlace();

      let street_number = "";
      let route = "";
      let postcode = "";
      let locality = "";

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            street_number = component.long_name;
            break;
          }

          case "route": {
            route = component.short_name;
            break;
          }

          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case "postal_code_suffix": {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }

          case "locality": {
            locality = component.long_name;
            break;
          }

          // case "administrative_area_level_1": {
          //   console.log("state: ", component.short_name);
          //   break;
          // }

          // case "country": {
          //   console.log("country: ", component.long_name);
          //   console.log("country: ", component.short_name);
          //   break;
          // }
        }
      }

      // street_number:  25
      // route:  Sint-Willibrordusplein
      // locality:  Heusden-Zolder
      // state:  Vlaams Gewest
      // country:  Belçika
      // country:  BE
      // address1:  Sint-Willibrordusplein 25
      // postcode:  3550
      // latitude:  51.0331523
      // longitude:  5.2814117

      this.verified_address = place.formatted_address;
      this.form.resto_lat = place.geometry.location.lat();
      this.form.resto_lng = place.geometry.location.lng();

      this.form.restaurant_address = `${route} ${street_number}`;
      this.form.restaurant_postcode = postcode.replace(/\D/g, "");
      this.form.restaurant_city = locality;

      setTimeout(() => {
        this.isCoordinateUptodate = true;
      }, 100);
    },
  }
}
</script>

<style scoped>
.mt-020 {
  margin-top: 0.20rem;
}
section.address {
  background: #F8F0DF;
  padding: 1rem;
  border-radius: 0.5rem;
}
</style>

<style>
input[type="radio"] + label {
  font-weight: normal;
}
.email.form-group > input {
  text-transform: lowercase;
}
.email.form-group > input::-webkit-input-placeholder,
.email.form-group > input:-moz-placeholder,
.email.form-group > input::-moz-placeholder,
.email.form-group > input:-ms-input-placeholder,
.email.form-group > input::placeholder {
  text-transform: none;
}

/* Remove Number Input's Arrows/Spinners */
/* Chrome, Safari, Edge, Opera */
#PostalCodeNum::-webkit-outer-spin-button,
#PostalCodeNum::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
}
/* Firefox */
#PostalCodeNum {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
